<template>
  <div>
    <landing-page-header></landing-page-header>
    <div>
      <div class="container mt-5">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link to="/home" tag="a">{{ $t('menu.home') }}</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ $t('menu.podcast') }}</li>
          </ol>
        </nav>
        <div class="row">
          <div class="col-12 text-center pt-3">
            <h2 class="title pl-1">{{ $t('menu.podcast') }}</h2>
          </div>
        </div>
        <div class="row" v-if="data && data.length == 0">
          <div class="col-12 pb-2">
            <a-empty
              :image="Empty.PRESENTED_IMAGE_SIMPLE"
              :image-style="{
                height: '60px',
              }"
            >
              <template #description>
                <span>
                  {{ $t('general.noData') }}
                </span>
              </template>
            </a-empty>
          </div>
        </div>
        <div v-if="data" class="row gx-5">
          <div v-for="audio in displayedData" :key="audio._id" class="col-md-6">
            <audio controls style="width: 100%;">
              <source :src="baseUrl + audio.audio" type="audio/mpeg" />
              {{ audio.description }}
            </audio>
            <p>{{ audio.discription }}</p>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12">
            <div class="d-flex justify-content-center">
              <div class="spinner-grow text-primary" role="status"></div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <a v-if="page != 1" @click="page = 1">«</a>
          <a v-if="page != 1" @click="page--">‹</a>
          <a
            v-for="pageNumber in pages.slice(page - 1, page + 5)"
            :class="{ selected: page == pageNumber }"
            :key="pageNumber"
            @click="page = pageNumber"
            >{{ pageNumber }}</a
          >
          <a v-if="page < pages.length" @click="page++">›</a>
          <a v-if="page < pages.length" @click="page = pages.length">»</a>
        </div>
      </div>
    </div>
    <div />
  </div>
</template>

<script>
/* eslint-disable */

import BookCard from '@/components/BookCard'
import apiAxios from '@/services/axios'
import LandingPageHeader from '@/components/LandingPageHeader'
import Vue from 'vue'
export default {
  name: 'Posts',
  components: { LandingPageHeader, BookCard },
  data: () => ({
    data: null,
    page: 1,
    perPage: 6,
    pages: [],
    baseUrl: process.env.VUE_APP_API_URL,
  }),
  computed: {
    displayedData() {
      return this.paginate(this.data)
    },
  },
  watch: {
    data() {
      this.setPages()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      apiAxios
        .post('/podcast/filter', {
          query: { status: 'active' },
        })
        .then(res => {
          this.data = res.data
        })
      //this.data = ["1","2"]
    },
    setPages() {
      let numberOfPages = Math.ceil(this.data.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(data) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return data.slice(from, to)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/mixins.scss';
.title {
  border-bottom: 2px solid $primary;
  width: 7em;
  text-align: left;
}

/* Pagination style */
@mixin transition-hover($x) {
  transition: all $x linear;
}

.pagination {
  text-align: center;
  padding: 20px 0;
  display: flex;
  justify-content: center;

  a {
    font-size: 1.08em;
    width: 50px;
    height: 50px;
    background-color: transparent;
    color: #222;
    border: 2px solid rgb(139, 139, 139);
    display: inline-block;
    vertical-align: middle;
    line-height: 45px;
    text-decoration: none;
    font-weight: 700;
    margin: 4px;

    @include transition-hover(0.2s);

    &:hover,
    &.selected {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }
}
</style>
